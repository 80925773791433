export const userData = [
  {
    id: "1",
    name: "Mike Qureshi",
    position: "CEO, Founder",
    description: "",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/mike.jpeg",
  },
  {
    id: "2",
    name: "Praveen K. Varshney",
    position: "Director, Co-Founder",
    description:
      "Praveen has been a principal of Varshney Capital Corp., a Vancouver based family office, since 1991.  He's also a Founding Director of Pyfera Growth Capital & a Founding Director of Humanitas Smart Planet Fund, both with a social impact focus.  Mr. Varshney obtained his UBC Sauder BComm in 1987 and is a FCPA, FCA",
    description_one:
      "He has been a Co-Founder, Director or Officer of many publicly traded companies over the years including one of Canada's largest fintech companies, Mogo Inc. (NASDAQ: MOGO & TSX: MOGO), NEXE Innovations & Little Kitchen Academy.  He’s also on four charity boards such as The Vancouver Foundation & Foundations for Social Change.",
    description_two:
      "Praveen is also a recipient of Business in Vancouver's 40 Under 40 Awards, the 2020 Greater Vancouver Board of Trade Wendy McDonald Diversity Awards Outstanding Mentor and 2021 inaugural Business in Vancouver Top 500 Most Influential Business Leaders in BC (Banking & Finance - Private Equity, Venture Capital & Hedge Funds category)",
    img: "/assets/img/team/praveen.png",
  },
  {
    id: "3",
    name: "James Dean Waryk",
    position: "Director, COO",
    description:
      "With deep expertise in Finance, Technology, and Business Administration, James is a seasoned Financial Consultant who brings a strategic edge to capital-raising efforts. Specializing in technology financing, venture capital, and private equity introductions, he has successfully helped companies secure over $100M in funding by leveraging a vast investor network and a results-driven consulting approach. Known for prioritizing strategic problem-solving and thorough management consulting before pursuing capital, James consistently delivers transformative outcomes that fuel business growth and financial success. Passionate about empowering companies to reach their full potential, He is always open to connecting and exploring new opportunities for collaboration.",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/james-dean-waryk.jpeg",
  },
  {
    id: "4",
    name: "Akshay Gupta",
    position: "Advisor",
    description: "",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/Akshay-gupta.jpeg",
  }

];
